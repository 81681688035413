
<script setup>
    import { ref, computed } from "vue";
    import { useCheckItems } from "./CheckItems.Composables.useCheckItems.ts";
    import { OActionList, OActionItem } from "o365-ui-components";
    import CheckItemSimple from "./CheckItems.CheckItemSimple.vue";
    import CheckItemsHeader from "./CheckItems.CheckItemsHeader.vue";

    const props = defineProps({
        dataObject: { type: Object, required: true },
        readonly: { type: Boolean, required: false },
        itemId: { type: Number, required: false },
        stepId: { type: Number, required: false },
        sectionId: { type: Number, required: false },
        promotedProcessId: { type: Number, required: false },
        inline: { type: Boolean, required: false },
        checkItems: { type: null, required: false },
    });
</script>

<template>
    <div style="position: relative;">
        <!-- <div class="bg-white" style="position: sticky; top: 4rem; z-index: 10;"> -->
            <!-- <button class="btn btn-success"> -->
                <!-- 0 / 24 -->
            <!-- </button> -->
        <!-- </div> -->
        <template v-if="inline">
            <CheckItemsHeader :checkItems="checkItems" />
        </template>
        <div class="d-flex flex-column gap-2">
            <template v-for="(row, index) in dataObject.data">
                <template v-if="row.IsHeader">
                    <div class="mt-2" />
                </template>
                <CheckItemSimple :dataObject="dataObject" :row="row" :checkItems="checkItems" />
            </template>
        </div>
    </div>
</template>
